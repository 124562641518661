export function getChalk() {

	// see: @babel/code-frame/lib/index.js
	return {
		grey: null,
		red: {
			bold: null,
		}
	};
}
